import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Login from '../Login/Login';
import './App.css';
import logo_neos from './logo_neos.png';
import 'bulma/css/bulma.min.css';
import { restoreSession, deleteSession } from '../../services/session/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MainScanner } from '../../components/Scanner/Scanner';

import Home from '../Home/Home';
import ScanPurchase from '../ScanPurchase/ScanPurchase';
import Purchase from '../Purchase/Purchase';

restoreSession();

class App extends Component {
  logout = () => {
    deleteSession();
  }

  render() {
    if (!this.props.userConnected) {
      return <Login />;
    }

    return (
      <Router>
        <div>
          <nav className="navbar is-light">
            <div className="navbar-brand" style={{ justifyContent: 'space-between' }}>
              <Link className="navbar-item" to="/">
                <img src={logo_neos} alt="Neo"height="28" />
              </Link>
              <div className="navbar-item">
                <span style={{ display: 'inline-block', marginRight: 16 }}>{this.props.user.given_name}</span>
                <span className="icon" onClick={this.logout}>
                  <FontAwesomeIcon icon="power-off" />
                </span>
              </div>
            </div>
          </nav>

          <MainScanner />

          <Route exact path="/" component={Home} />
          <Route exact path="/scan" component={ScanPurchase} />
          <Route path="/purchase/:id/" component={Purchase} />
        </div>
      </Router>
    );
  }
}

export default App;
