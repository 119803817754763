import {
  USER_CONNECT,
  USER_DISCONNECT,
} from './session.actions';

export default (state = { connected: false }, action) => {
  switch (action.type) {
  case USER_CONNECT:
    return {
      connected: true,
      user: action.user,
    };
  case USER_DISCONNECT:
    return {
      connected: false,
    };
  default:
    return state;
  }
};
