import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './scenes/App/AppContainer';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './services/store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faLock, faPowerOff, faQrcode, faPlus, faMinus, faSpinner, faExclamationCircle, faCheckCircle, faBarcode, faSearch, faEye, faHandPaper, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import 'moment/locale/fr';
import * as ScanditSDK from 'scandit-sdk';
import { SCANDIT_LICENCE_KEY } from './config';

ScanditSDK.configure(SCANDIT_LICENCE_KEY, {
  engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk/build',
  preloadEngineLibrary: false,
  preloadCameras: false,
});

library.add(faEnvelope, faLock, faPowerOff, faQrcode, faPlus, faMinus, faSpinner, faExclamationCircle, faCheckCircle, faBarcode, faSearch, faEye, faHandPaper, faCheck, faTimes);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
