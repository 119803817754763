import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class EditItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nbScanned: props.item.nbScanned,
    };
  }

  render() {
    const item = this.props.item;

    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="box">
            <h4 className="title is-5">{item.name}</h4>
            <nav className="level is-mobile">
              <div className="level-item has-text-centered">
                <div>
                  <p className="title">
                    <a
                      href
                      className="button is-rounded"
                      onClick={() => this.setState({ nbScanned: Math.max(0, this.state.nbScanned - 1) })}
                      disabled={this.state.nbScanned === 0}
                    >
                      <span className="icon is-small">
                        <FontAwesomeIcon icon="minus" />
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <p className="title">{this.state.nbScanned}</p>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <p className="title">
                    <a
                      href
                      className="button is-rounded"
                      onClick={() => this.setState({ nbScanned: this.state.nbScanned + 1 })}
                    >
                      <span className="icon is-small">
                        <FontAwesomeIcon icon="plus" />
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </nav>
            <div
              className="button is-link is-medium is-fullwidth"
              style={{
                marginTop: '2rem',
              }}
              onClick={() => this.props.onValidate({ nbScanned: this.state.nbScanned })}
            >
              Enregistrer
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditItem.propTypes = {
  item: PropTypes.shape({
    nbScanned: PropTypes.number.isRequired,
    quantity: PropTypes.number,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default EditItem;
