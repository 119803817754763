import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VerificationAction = ({ verification_strategy, danger }) => {
  let icon, message;

  if (danger) {
    icon = 'times';
    message = danger;
  } else if (verification_strategy === 'none') {
    icon = 'check';
    message = (<span>Pas de contrôle nécessaire.<br />Vous pouvez laisser partir ce client.</span>);
  } else if (verification_strategy === 'quick') {
    icon = 'eye';
    message = (<span>Merci de vérifier visuellement l’ensemble du panier du client.</span>);
  } else {
    icon = 'hand-paper';
    if (verification_strategy === 'partial') {
      message = (<span>Merci de scanner au moins la moitié des articles du client.<br />Privilégiez les produits sensibles.</span>);
    } else {
      message = (<span>Merci de scanner l’ensemble du panier du client.</span>);
    }
  }


  return (
    <div
      style={{
        textAlign: 'center',
        color: 'white',
        padding: '0.75em',
      }}
    >
      <FontAwesomeIcon icon={icon} size="6x" />
      <br />
      <div style={{
        fontSize: '2em',
        marginTop: '1rem',
      }}>{message}</div>
    </div>
  );
};

VerificationAction.propTypes = {
  verification_strategy: PropTypes.oneOf(['total', 'partial', 'none', 'blocked', 'quick']).isRequired,
  verified: PropTypes.bool.isRequired,
  can_scan: PropTypes.bool,
};

export default VerificationAction;
