import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createSession } from '../../services/session/session';

export default class extends Component {
  state = {
    email: '',
    password: '',
    error: undefined,
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  }
  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  }
  handleSubmit = async (e) => {
    this.setState({
      error: undefined,
    });
    e.preventDefault();
    createSession(this.state).catch(() => {
      this.setState({
        error: true,
      });
    });
  }

  render() {
    return (
      <div style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <div className="card" style={{ width: '100%', marginBottom: 50 }}>
          <header className="card-header">
            <p className="card-header-title">
              Neos | Controls
            </p>
          </header>
          <div className="card-content">
            <div className="content">
              <form onSubmit={this.handleSubmit}>
                <div className="field">
                  <p className="control has-icons-left">
                    <input className="input" type="email" placeholder="Adresse email" onChange={this.handleEmailChange} />
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon="envelope" />
                    </span>
                  </p>
                </div>
                <div className="field">
                  <p className="control has-icons-left">
                    <input className="input" type="password" placeholder="Mot de passe" onChange={this.handlePasswordChange} />
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon="lock" />
                    </span>
                  </p>
                </div>
                {this.state.error && (
                  <div className="notification is-danger" style={{ marginBottom: 0 }}>
                    Erreur d’email ou de mot de passe.
                  </div>
                )}
                <div className="field">
                  <p className="control">
                    <input type="submit" className="button is-success" style={{ width: '100%', marginTop: '1rem' }} value="Connexion" />
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
