import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '80vh',
      padding: 16,
      justifyContent: 'center',
    }}>
      <Link className="button is-large is-warning" to="/scan" style={{ width: '100%' }}>
        <span className="icon is-medium">
          <FontAwesomeIcon icon="qrcode" />
        </span>
        <span>Scanner</span>
      </Link>
    </div>
  );
};
