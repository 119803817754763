import React, { Component } from 'react';
import { getPurchaseVerification } from '../../services/api';
import _ from 'lodash';
import Price from '../../components/Price/Price';
import VerificationAction from '../../components/VerificationAction/VerificationAction';
import ScanArticles from './ScanArticles';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SaveControl from './SaveControl';
import classNames from 'classnames';

export default class extends Component {
  state = {
    quickSaving: false,
    isValid: undefined,
    showScanButtons: undefined,
    error: undefined,
  }

  async componentDidMount() {
    try {
      const data = await getPurchaseVerification(this.props.match.params.id);
      this.setState({
        ...data,
        showScanButtons: data.verification && (data.verification.verification_strategy !== 'none' && !data.verification.danger),
      });
    } catch (e) {
      this.setState({
        error: true,
      });
    }
  }

  quickSaving = (isValid) => {
    this.setState({
      quickSaving: true,
      isValid,
    });
  }

  render() {
    const purchase = this.state.purchase;
    const verification = this.state.verification;

    if (this.state.error) {
      return (
        <div style={{ margin: '0.75rem' }}>
          <div className="notification is-danger">Ce reçu est introuvable</div>
          <Link
            className="button is-large is-fullwidth"
            to="/scan"
          >
            Retour
          </Link>
        </div>
      );
    }

    if (!purchase) {
      return (<div>Chargement...</div>);
    }

    if (!purchase.store.accessible) {
      return (
        <div className="notification is-danger" style={{ margin: 8 }}>
          Ce panier {purchase.short_identifier} a été créé dans un autre magasin ({purchase.store.name}), auquel vous n’avez pas accès.
        </div>
      );
    }

    if (this.props.location.pathname.match(/\/scan$/)) {
      return (
        <ScanArticles
          purchase={this.state.purchase}
        />
      );
    }

    return (
      <div
        style={{
          minHeight: 'calc(100vh - 3.25rem)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        className={classNames({
          'has-background-success': verification.verification_strategy === 'none',
          'has-background-warning': verification.verification_strategy === 'partial',
          'has-background-danger': (verification.verification_strategy === 'total' || verification.verification_strategy === 'blocked' || verification.verification_strategy === 'quick'),
        })}
      >
        <VerificationAction {...verification} />

        {!verification.danger && (
          <div
            className="has-text-white is-size-5"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div>Articles&nbsp;:&nbsp;{_.sumBy(purchase.items, 'quantity')}</div>
          </div>
        )}

        {verification.verification_strategy === 'quick' && (
          <table
            className="table is-size-6 is-fullwidth has-text-white"
            style={{
              margin: 0,
              background: 'none',
            }}
          >
            <thead>
              <tr>
                <th className="has-text-white">Nom</th>
                <th className="has-text-white">Qté.</th>
                <th className="has-text-white">Prix tt.</th>
              </tr>
            </thead>
            <tbody>
              {purchase.items.map((item) => {
                return (
                  <tr key={item.barcode}>
                    <td>{item.name}</td>
                    <td>{item.quantity}</td>
                    <td><Price cents={item.unit_price.cents * item.quantity} currency={item.unit_price.currency} /></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}


        {this.state.quickSaving && (
          <SaveControl
            onCancel={() => this.setState({ quickSaving: false })}
            purchase={this.state.purchase}
            items={[]}
            isValid={this.state.isValid}
          />
        )}

        <div
          style={{
            padding: '0.75rem',
          }}
        >
          {verification.verification_strategy === 'quick' && (
            <div
              className="button is-success is-large is-fullwidth"
              onClick={() => this.quickSaving(true)}
              style={{ marginBottom: '0.5rem' }}
            >
              <span className="icon is-medium">
                <FontAwesomeIcon icon="check-circle" />
              </span>
              <span>Tout est en ordre</span>
            </div>
          )}

          {verification.can_scan && (
            <Link
              className="button is-link is-large is-fullwidth"
              to="scan"
              style={{ marginBottom: '0.5rem' }}
            >
              <span className="icon is-medium">
                <FontAwesomeIcon icon="barcode" />
              </span>
              <span>Démarrer le scan</span>
            </Link>
          )}

          {verification.verification_strategy !== 'blocked' && (
            <div
              className={classNames('button is-warning is-large is-fullwidth', { 'is-outlined is-inverted': verification.verification_strategy === 'partial' })}
              onClick={() => this.quickSaving(false)}
            >
              <span className="icon is-medium">
                <FontAwesomeIcon icon="exclamation-circle" />
              </span>
              <span>Signaler un problème</span>
            </div>
          )}

          <Link
            className="button is-large is-fullwidth"
            to="/"
            style={{ marginTop: '0.5rem' }}
          >
            {verification.verification_strategy === 'none' ? 'Ok' : 'Retour'}
          </Link>
        </div>
      </div>
    );
  }
}
