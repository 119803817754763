import React from 'react';
import PropTypes from 'prop-types';

const Price = ({ cents, currency }) => {
  let currencyBefore = '', currencyAfter = '';
  if (currency === 'EUR') {
    currencyAfter = ' €';
  } else if (currency === 'USD') {
    currencyBefore = '$';
  }
  return (
    <span>{`${currencyBefore}${(cents/100).toFixed(2).replace('.', ',')}${currencyAfter}`}</span>
  );
};

Price.propTypes = {
  cents: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

export default Price;
