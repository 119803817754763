import React, { Component } from 'react';
import * as ScanditSDK from 'scandit-sdk';
import './Scanner.css';

let barcodePicker, hookReady;

class MainScanner extends Component {
  async componentDidMount() {
    barcodePicker = await ScanditSDK.BarcodePicker.create(document.getElementById('main-barcode-scanner'), {
      playSoundOnScan: false,
      vibrateOnScan: false,
    });
    barcodePicker.setGuiStyle(ScanditSDK.BarcodePicker.GuiStyle.LASER);
    barcodePicker.pauseScanning();
    barcodePicker.setVisible(false);
    barcodePicker.setVideoFit('cover');

    if (hookReady) {
      hookReady();
      hookReady = undefined;
    }
  }

  componentWillUnmount() {
    barcodePicker.destroy();
  }

  render() {
    return (
      <div
        id="main-barcode-scanner"
        style={{
          display: 'none',
        }}
      />
    );
  }
}

class Scanner extends Component {
  componentDidMount() {
    this.element = document.getElementById('barcode-scanner');
    if (barcodePicker) {
      this.setUpScanner();
    } else {
      hookReady = this.setUpScanner;
    }
  }

  setUpScanner = () => {
    if (barcodePicker) {
      barcodePicker.reassignOriginElement(this.element);
      barcodePicker.onScan((scanResult) => this.props.onScan(scanResult));
      barcodePicker.applyScanSettings(this.props.scanSettings);
      barcodePicker.setVisible(true);
      barcodePicker.resumeScanning();
      barcodePicker.setGuiStyle(this.props.guiStyle);
      if (this.props.showBarcodesPositions) {
        barcodePicker.onScan((scanResult) => {
          scanResult.barcodes.forEach((barcode) => {
            this.drawBarcodePosition({
              barcode,
              color: '141, 71%, 48%',
            });
          });
        });
      }
      barcodePicker.onScan(() => {
        barcodePicker.pauseScanning();
        setTimeout(() => {
          if (!this.props.pause && barcodePicker.isVisible()) {
            barcodePicker.resumeScanning();
          }
        }, 1500);
      });
    }
  }

  componentWillUnmount() {
    barcodePicker.reassignOriginElement(document.getElementById('main-barcode-scanner'));
    barcodePicker.removeScanListeners();
    barcodePicker.pauseScanning();
    barcodePicker.setVisible(false);
  }

  drawBarcodePosition({ barcode, hslColor }) {
    const location = barcode.location;
    const currentResolution = barcodePicker.getActiveCamera().currentResolution;
    const eWidth  = this.element.offsetWidth;
    const eHeight = this.element.offsetHeight;
    const n  = 1.3 * Math.max(eWidth / currentResolution.width, eHeight / currentResolution.height);
    this.e   = document.createElement('canvas');
    this.e.className = 'barcode-position';
    this.e.width     = eWidth;
    this.e.height    = eHeight;
    const r = (eWidth - currentResolution.width * n) / 2;
    const o = (eHeight - currentResolution.height * n) / 2;
    const a = this.e.getContext('2d');
    const s = r + location.topLeft.x * n;
    const c = o + location.topLeft.y * n;
    const l = r + location.topRight.x * n;
    const u = o + location.topRight.y * n;
    const d = r + location.bottomRight.x * n;
    const f = o + location.bottomRight.y * n;
    const p = r + location.bottomLeft.x * n;
    const m = o + location.bottomLeft.y * n;
    a.beginPath();
    a.moveTo(s, c);
    a.lineTo(l, u);
    a.lineTo(d, f);
    a.lineTo(p, m);
    a.closePath();
    a.fillStyle = `hsla(${hslColor}, 0.8)`;
    a.fill();
    a.lineWidth = 3 * n;
    a.strokeStyle = `hsla(${hslColor}, 0.95)`;
    a.stroke();
    // mirrorMode && (i.style.transform = 'scale(-1, 1)'),
    this.element.appendChild(this.e);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pause !== this.props.pause) {
      if (this.props.pause) {
        barcodePicker.pauseScanning();
      } else {
        barcodePicker.resumeScanning();
      }
    }
    if (prevProps.guiStyle !== this.props.guiStyle) {
      barcodePicker.setGuiStyle(this.props.guiStyle);
    }

    if (this.props.showBarcode && prevProps.showBarcode !== this.props.showBarcode) {
      this.drawBarcodePosition(this.props.showBarcode);
    } else if (!this.props.showBarcode && this.e) {
      this.element.removeChild(this.e);
      delete this.e;
    }
  }

  render() {
    return (
      <div
        id="barcode-scanner"
        style={{
          width: '100%',
          height: this.props.height || '200px',
          position: 'relative',
        }}
      />
    );
  }
}

export {
  MainScanner,
  Scanner,
};
