import App from './App';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    userConnected: state.session.connected,
    user: state.session.user,
  };
};

export default connect(
  mapStateToProps,
)(App);
