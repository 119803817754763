import React, { Component } from 'react';
import { getBarcodesProducts } from '../../services/api';
import _ from 'lodash';
import * as ScanditSDK from 'scandit-sdk';
import { Scanner } from '../../components/Scanner/Scanner';
import classNames from 'classnames';
import Price from '../../components/Price/Price';
import EditItem from './EditItem';
import SaveControl from './SaveControl';
import ConfirmError from './ConfirmError';
import 'animate.css';

const STORAGE_ITEMS_KEY = 'ScanArticles.items';

class ScanArticles extends Component {
  state = {
    items: {},
    editingItem: undefined,
    saving: false,
  }

  componentDidMount() {
    const savedItems = localStorage.getItem(STORAGE_ITEMS_KEY);
    let items = {};
    if (savedItems) {
      items = JSON.parse(savedItems);
    } else if (this.props.purchase.items) {
      this.props.purchase.items.forEach((item) => {
        items[item.barcode] = {
          nbScanned: 0,
          ...item,
        };
      });
    }
    this.setState({
      items,
    });
  }

  onScan = async (scanResult) => {
    const barcode = scanResult.barcodes[0].data;
    const items = { ...this.state.items };

    if (!items[barcode] || items[barcode].nbScanned >= items[barcode].quantity) {
      this.setState({
        showBarcode: {
          barcode: scanResult.barcodes[0],
          hslColor: '348, 100%, 61%',
        },
      });
    } else {
      this.setState({
        showBarcode: {
          barcode: scanResult.barcodes[0],
          hslColor: '141, 71%, 48%',
        },
      });
    }
    setTimeout(() => {
      this.setState({
        showBarcode: undefined,
      });
    }, 1500);

    if (!items[barcode]) {
      const data = await getBarcodesProducts(this.props.purchase.store.id, [barcode]);
      data.missing_products.forEach((product) => {
        if (!items[product.barcode.value]) {
          items[product.barcode.value] = { nbScanned: 0, quantity: 0, unknown: true, name: 'Article inconnu', unit_price: { cents: 0 }, barcode: product.barcode.value };
        }
      });
      data.found_products.forEach((product) => {
        if (!items[product.barcode.value]) {
          items[product.barcode.value] = { nbScanned: 0, quantity: 0, ...product, barcode: product.barcode.value };
        }
      });
    }

    items[barcode].nbScanned += 1;
    this.setState({
      items,
    });
  }

  componentDidUpdate() {
    localStorage.setItem(STORAGE_ITEMS_KEY, JSON.stringify(this.state.items));
  }
  componentWillUnmount() {
    localStorage.removeItem(STORAGE_ITEMS_KEY);
  }

  setItemNbScanned(item, nbScanned) {
    const itemsUpdated = { ...this.state.items };
    if (nbScanned === 0 && item.quantity === 0) {
      delete itemsUpdated[item.barcode];
    } else {
      itemsUpdated[item.barcode].nbScanned = nbScanned;
    }
    this.setState({
      items: itemsUpdated,
    });
  }

  render() {
    const purchase     = this.props.purchase;
    const scanSettings = new ScanditSDK.ScanSettings({
      enabledSymbologies: ['ean13', 'ean8', 'code128'],
      codeDuplicateFilter: 3000,
    });

    const items = _.values(this.state.items);

    const nbScanned = _.sumBy(items, (item) => item.nbScanned);
    const priceScanned = _.sumBy(items, (item) => item.nbScanned * item.unit_price.cents);
    const itemErrored = items.find((item) => item.nbScanned > item.quantity);

    const itemsToSave = _.values(this.state.items).filter(item => item.nbScanned > 0).map(item => _.pick(item, ['nbScanned', 'barcode']));

    return (
      <div>
        <h1
          className="title is-5 has-text-centered"
          style={{ margin: 8 }}
        >
          Panier {purchase.short_identifier}
        </h1>

        <nav className="level is-mobile">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Articles scannés/payés</p>
              <p className="title">
                {nbScanned}
                <span className="is-size-5">/{_.sumBy(purchase.items, 'quantity')}</span>
              </p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Montant scanné/payé</p>
              <p className="title">
                <Price currency={purchase.total_price.currency} cents={priceScanned} />
                <span className="is-size-5">/<Price {...purchase.total_price} /></span>
              </p>
            </div>
          </div>
        </nav>

        <Scanner
          onScan={this.onScan}
          scanSettings={scanSettings}
          pause={this.state.editingItem || itemErrored}
          showBarcode={this.state.showBarcode}
          guiStyle={ScanditSDK.BarcodePicker.GuiStyle.LASER}
        />

        <table
          className="table is-size-6 is-fullwidth"
          style={{
            marginTop: '1rem',
            marginBottom: '4rem',
          }}
        >
          <thead>
            <tr>
              <th>Code</th>
              <th>Nom</th>
              <th>Qté.</th>
              <th>Prix</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              const padding = '0.15em 0.3em';
              return (
                <tr
                  key={item.barcode}
                  className={classNames({
                    'has-text-white has-background-success': item.nbScanned === item.quantity,
                    'has-text-white has-background-danger': item.nbScanned > item.quantity,
                    'animated shake': this.state.showBarcode && this.state.showBarcode.barcode.data === item.barcode,
                  })}
                  onClick={() => this.setState({ editingItem: item })}
                >
                  <td style={{ padding, fontSize: '0.5rem', verticalAlign: 'middle' }}>{item.barcode}</td>
                  <td style={{ padding, verticalAlign: 'middle' }}>{item.name}</td>
                  <td style={{ padding, verticalAlign: 'middle' }} className="has-text-weight-bold">{item.nbScanned}/{item.quantity}</td>
                  <td style={{ padding, verticalAlign: 'middle' }}>
                    {item.unknown ? '—' : (
                      <Price cents={item.unit_price.cents * item.nbScanned} currency={item.unit_price.currency} />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <div
            className="button is-medium is-fullwidth is-success"
            onClick={() => this.setState({ saving: true })}
            style={{
              marginTop: '2rem',
            }}
          >
            Valider le contrôle
          </div>
        </div>

        {this.state.saving && (
          <SaveControl
            purchase={this.props.purchase}
            items={itemsToSave}
            onCancel={() => this.setState({ saving: false })}
          />
        )}

        {this.state.editingItem && (
          <EditItem item={this.state.editingItem} onValidate={({ nbScanned }) => {
            const item = this.state.editingItem;
            this.setItemNbScanned(item, nbScanned);
            this.setState({
              editingItem: undefined,
            });
          }} />
        )}

        {itemErrored && (
          <ConfirmError
            item={itemErrored}
            onCancel={() => {
              this.setItemNbScanned(itemErrored, itemErrored.quantity);
            }}
            items={itemsToSave}
            purchase={purchase}
          />
        )}
      </div>
    );
  }
}

export default ScanArticles;
