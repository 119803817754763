export const USER_CONNECT    = 'USER_CONNECT';
export const USER_DISCONNECT = 'USER_DISCONNECT';


export const userConnect = (user) => {
  return { type: USER_CONNECT, user };
};
export const userDisconnect = () => {
  return { type: USER_DISCONNECT };
};
