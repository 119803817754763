import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { savePurchaseVerification } from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

class SaveControl extends Component {
  state = {
    saving: false,
    saved: false,
  }

  onSave = () => {
    this.setState({ saving: true, saved: false, error: undefined });
    savePurchaseVerification(
      this.props.purchase.id,
      this.props.items.map((item) => {
        return { barcode: item.barcode, quantity: item.nbScanned };
      }),
      this.props.isValid,
    ).then(() => {
      if (this.props.onSave) {
        this.props.onSave();
      }
    }).catch((error) => {
      if (error.response && error.response.data) {
        this.setState({ error: error.response.data.error });
      } else {
        this.setState({ error: 'Une erreur inconnue s’est produite.'});
      }
    }).then(() => {
      this.setState({
        saving: false,
        saved: true,
      });
    });
  }

  render() {
    const { saved, saving, error } = this.state;

    let title = 'Enregistrer le contrôle';
    let confirmButtonText = 'Enregistrer';
    let confirmButtonClass = 'is-success';
    if (this.props.isValid === false) {
      title = 'Confirmez le signalement d’un problème';
      confirmButtonText = 'Confirmer';
      confirmButtonClass = 'is-danger';
    } else if (this.props.isValid === true) {
      title = 'Confirmez que tout est correct';
      confirmButtonText = 'Confirmer';
      confirmButtonClass = 'is-success';
    }

    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="box">
            <h4 className="title is-5" style={{ marginBottom: '1rem' }}>{title}</h4>

            {saving && (
              <div className="has-text-centered">
                <span className="icon is-large">
                  <FontAwesomeIcon icon="spinner" className="fa-pulse" />
                </span>
              </div>
            )}

            {saved && error && (
              <div>
                <div className="notification is-danger">
                  Impossible de sauvegarder le contrôle&nbsp;: {error}
                </div>
                <a
                  href
                  className="button is-fullwidth is-medium is-primary"
                  onClick={this.onSave}
                  style={{
                    marginBottom: '0.5rem',
                  }}
                >
                  Rééssayer
                </a>
                <a
                  href
                  className="button is-fullwidth is-medium"
                  onClick={this.props.onCancel}
                >
                  Retourner au panier
                </a>
              </div>
            )}

            {saved && !error && (
              <div>
                <div className="notification is-info">
                  Le contrôle a bien été sauvegardé.
                </div>
                <a
                  href
                  className="button is-fullwidth is-medium"
                  onClick={() => this.props.history.push('/')}
                >
                  Ok
                </a>
              </div>
            )}

            {!saving && !saved && (
              <div>
                <div className="columns">
                  <div className="column">
                    <button className={classNames('button is-link is-fullwidth is-medium', confirmButtonClass)} onClick={this.onSave}>
                      {confirmButtonText}
                    </button>
                  </div>
                  <div className="column">
                    <button className="button is-fullwidth is-medium" onClick={this.props.onCancel}>
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SaveControl.propTypes = {
  purchase: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    barcode: PropTypes.string.isRequired,
    nbScanned: PropTypes.number.isRequired,
  })).isRequired,
  onCancel: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};

export default withRouter(SaveControl);
