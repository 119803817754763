import { signIn, backofficeUser } from '../api';
import { store } from '../store';
import { userConnect, userDisconnect } from './session.actions';

export const createSession = async ({ email, password }) => {
  const signInResponse = await signIn({ email, password });
  const data = signInResponse.data;

  localStorage.setItem('user.email', data.user.email);
  localStorage.setItem('user.token', data.token);
  localStorage.setItem('user.id', data.user.id);

  const user = await backofficeUser(data.user.id);
  store.dispatch(userConnect(user.data));

  return user.data;
};

export const deleteSession = () => {
  localStorage.clear();
  store.dispatch(userDisconnect());
};

export const restoreSession = async () => {
  if (localStorage.getItem('user.email') && localStorage.getItem('user.token') && localStorage.getItem('user.id')) {
    const user = await backofficeUser(localStorage.getItem('user.id'));
    store.dispatch(userConnect(user.data));
  }
};
