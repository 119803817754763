import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Price from '../../components/Price/Price';
import SaveControl from './SaveControl';

export default class extends Component {
  state = {
    confirming: true,
    saving: false,
  }

  onConfirm = () => {
    this.setState({
      confirming: false,
    });
  }

  render() {
    const item = this.props.item;

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          color: 'white',
          zIndex: 10,
          textAlign: 'center',
          padding: 8,
          paddingTop: '4rem',
          overflow: 'auto',
        }}
        className={this.state.confirming ? 'has-background-danger' : 'has-background-info'}
      >
        {this.state.confirming ? (<>
          <FontAwesomeIcon icon="exclamation-circle" size="6x" />

          <div style={{ fontSize: '1.5em', marginTop: 16 }}>
            Vous venez de scanner un article qui ne figurait pas dans le panier du client.
          </div>

          <div style={{ marginTop: 16, marginBottom: 24, fontSize: '2em' }}>
            <div>{item.name}</div>
            <div><Price {...item.unit_price} /></div>
            <div>{item.nbScanned} scanné{item.nbScanned > 1 ? 's' : ''} <i style={{ fontSize: '0.5em' }}>vs</i> {item.quantity} acheté{item.quantity > 1 ? 's' : ''}</div>
          </div>

          <div>
            <button
              className="button is-fullwidth is-medium is-danger is-inverted is-outlined"
              style={{ marginBottom: 16 }}
              onClick={() => this.setState({ saving: true })}
            >
              Confirmer l‘écart
            </button>
            <button className="button is-fullwidth is-medium is-link" onClick={this.props.onCancel}>
              Annuler
            </button>
          </div>

          {this.state.saving && (
            <SaveControl
              purchase={this.props.purchase}
              items={this.props.items}
              onCancel={() => this.setState({ saving: false })}
              onSave={() => this.setState({ saving: false, confirming: false })}
              isValid={false}
            />
          )}
        </>) : (<>
          <div style={{ marginTop: 32, fontSize: '1.5em' }}>
            Merci de votre signalement.
            <br /><br />
            Vous pouvez maintenant suivre la procédure habituelle en cas de fraude.
          </div>

          <button
            style={{ marginTop: 32 }}
            className="button is-fullwidth is-medium is-primary"
            onClick={this.props.onFinish}
          >
            OK
          </button>
        </>)}
      </div>
    );
  }
}
