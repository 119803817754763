import React, { Component } from 'react';
import * as ScanditSDK from 'scandit-sdk';
import { withRouter } from 'react-router-dom';
import { Scanner } from '../../components/Scanner/Scanner';
import { Link } from 'react-router-dom';

class ScanPurchase extends Component {
  onScan = (scanResult) => {
    const purchaseId = scanResult.barcodes[0].data;
    if (purchaseId) {
      this.props.history.push(`/purchase/${purchaseId}/`);
    }
  }

  render() {
    const scanSettings = new ScanditSDK.ScanSettings({
      enabledSymbologies: [ScanditSDK.Barcode.Symbology.QR],
    });
    return (
      <div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Scanner
            onScan={this.onScan}
            scanSettings={scanSettings}
            guiStyle={ScanditSDK.BarcodePicker.GuiStyle.VIEWFINDER}
            height="350px"
          />
        </div>
        <div style={{ margin: 16 }}>
          <p className="has-text-centered">Scannez le QRCode d’un reçu pour contrôler celui-ci.</p>
          <div style={{ marginTop: 64 }}>
            <Link
              className="button is-large is-fullwidth"
              to="/"
            >
              Retour
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ScanPurchase);
