import axios from 'axios';
import { API_URL } from '../config';

const apiAxios = () => {
  const headers = {
    'Accept': 'application/json',
  };
  if (localStorage.getItem('user.email') && localStorage.getItem('user.token')) {
    headers['X-BACKOFFICE-USER-EMAIL'] = localStorage.getItem('user.email');
    headers['X-BACKOFFICE-USER-TOKEN'] = localStorage.getItem('user.token');
  }
  return axios.create({
    baseURL: API_URL,
    headers,
    timeout: 4000,
  });
};

export const signIn = ({ email, password }) => {
  return apiAxios().post('/backoffice/users/sign_in', {
    backoffice_user: {
      email,
      password,
    },
  });
};

export const backofficeUser = (userId) => {
  return apiAxios().get(`/backoffice/users/${userId}`);
};

export const getBarcodesProducts = async (storeId, barcodes) => {
  const products = await apiAxios().get(`/stores/${storeId}/products?barcodes=${barcodes.join(',')}`);
  return products.data;
};

export const getPurchaseVerification = async (purchaseId) => {
  const purchase = await apiAxios().get(`/backoffice/purchases/${purchaseId}/verification`);
  return purchase.data;
};
export const savePurchaseVerification = async (purchaseId, items, is_valid) => {
  return apiAxios().post(`/backoffice/purchases/${purchaseId}/verification`, { items, is_valid });
};
